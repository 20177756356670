@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

body {
  background-color:#000;
  color:#fff;
  font-family: 'Arial';
  font-size:1vw;
  position: grid;
  text-align:center;
  margin:0;
  overflow-x:hidden;
}

.content {
  margin-bottom: 15rem;
}

@media only screen and (max-width: 1024px) {
 .mtvrs-round {display: none;}
 /* .top-menu {display:none;} */
}

.mtvrs-round {width:20vw;}
.header-banner {width:22vw;}
.footer-banner {width:10vw;}

.top-nav {
  top:0;
  margin:0;
  padding-top:15px;
  padding-bottom:15px;
  background-color:#3a3a3a;
  border-bottom: 1px solid #efefef;
}

.top-nav a {
  font-family: sans-serif;
  color:#efefef;
  font-size:0.8vw;
  text-decoration:none;
  margin:0 15px;
  text-transform:uppercase;
}

.top-nav a:hover {
  text-decoration:underline;
}

.top-menu {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: 0;
  /* padding-top: 15px;
  padding-bottom: 15px; */
  right: 15px;
  height: 42px;
  min-width: 256px;
}

.wallet-link, .wallet-link:visited, .wallet-link:active {
  color: aliceblue;
  text-decoration: none;
}

.wallet-balance {
  font-size: 1.15em;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

h1 {
  font-family: 'Montserrat',sans-serif;
  color: #9460FF;
  font-size: 5vw;
  font-weight: 900;
  line-height:1;
  padding:0;
  margin:0;
  text-transform:uppercase;

}

h2 {
  font-family: 'Montserrat',sans-serif;
  color: #7AC1FF;
  font-size: 4vw;
  font-weight: 900;
  line-height:1;
  padding:0;
  margin:0;
  text-transform:uppercase;
}

h3 {
  font-family: 'Montserrat',sans-serif;
  /*color: #98FF9B;*/
  color: #98FF9B;
  font-size: 1vw;
  line-height:1;
  padding:0;
  margin-bottom:8px;
  font-weight:bold;
}

p {
  color: #fff;
  font-size:0.8vw;
  padding:0;
  margin:0;
}

.collectionContent {
  text-align: center;
}

.divTable {
	display: table;
	width: 50%;
  margin: auto;
}
.divTableRow {
	display: table-row;
}
.divTableCell {
	display: table-cell;
  padding: 10 10;
}

.collection-image {
  width:10vw;
}

.item-image {
  width:10vw;
}

.item-count-badge span {
  cursor: default;
  color: black;
  font-size:18px;
  padding:20px;
  background-color: #7AC1FF;
  border-radius:20px;
}

.common-background {
  background-image: radial-gradient(rgb(246, 247, 250), rgb(136, 141, 143));
}

.uncommon-background {
  background-image: radial-gradient(rgb(255, 133, 99), rgb(237, 109, 79));
}

.rare-background {
  background-image: radial-gradient(rgb(58, 214, 130), rgb(54, 207, 117));
}

.epic-background {
  background-image: radial-gradient(rgb(99, 151, 242), rgb(61, 133, 230));
}

.legendary-background {
  background-image: radial-gradient(rgb(166, 87, 237), rgb(132, 45, 218));
}

.mythic-background {
  background-image: radial-gradient(rgb(251, 125, 227), rgb(255, 99, 225));
}

.footer {
  background-color:#292929;
  color:#cecece;
  font-size:0.7vw;
  margin-top:60px;
  padding-top:15px;
  padding-bottom:5px;
  bottom: 0;
  width: 100%;
}

ul.social-links {
  margin: 0;
  padding: 0;
  padding-top: 0.3rem;
  list-style-type: none;
}

ul.social-links li {
  display: inline-flex;
  margin: 0.25rem;
}

ul.social-links .discord svg path { fill: white; }
ul.social-links .twitter svg path { fill: #55acee; }
ul.social-links .youtube svg path { fill: red; }
ul.social-links .reddit svg path { fill: rgb(255, 87, 0); }
ul.social-links .facebook svg path { fill: rgb(59, 89, 152); }
ul.social-links .instagram svg path { fill: rgb(233, 89, 80); }

.button {
  /* padding:5px 20px; */
  padding-inline: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 28px;
  font-size: 0.8vw;
  font-weight: bolder;
  color: #000;
  border-radius: 20px;
  width: 100%;
  /* margin-top: 5px; */
}

.connectbutton {
  background-color:#7ac1ff !important;
}

.buybutton {
  background-color:#98ff9b !important;
}

.exchangebutton {
  background-color:#7ac1ff !important;
}

.returnbutton {
  background-color:#9460ff !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.exchange-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  justify-content: center;
  box-sizing: border-box;
}

.exchange-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.5s;
  cursor: pointer;
  padding: 1rem;
  min-width: 150px;
}

.exchange-grid-item-selected {
  border: 2px solid green;
  border-radius: 10px;
}

.exchange-grid-item:hover {
  transform: scale(1.2);
}

.exchange-grid-item img {
  margin: auto;
  margin-top: 1rem;
  border-radius: 5px;
}

/* TODO: eventually move this to a mui theme provider */
.swal2-title, .swal2-html-container, .exchange-grid-item strong {
  color: aliceblue !important;
}

.swal2-popup {
  background-color: #121212 !important;
  border: 1px solid white !important;
}

.MuiInput-underline {
  border-bottom: 1px solid white;
}

.MuiDataGrid-row {
  cursor: pointer;
}

button.btn {
  border-radius: 20px;
  font-weight: 800;
  font-size: 85%;
  padding: 0.125em;
  width: 100%;
  margin-top: 10px;
}

/* ---- */

@media only screen and (max-width: 768px) {
  .top-nav a {
    font-size: 2em;
  }

  .top-menu {
    padding: 0;
    font-size: 1rem;
  }

  .exchange-grid-item strong {
    min-height: 5ch;
  }
}
